import { ColorShade, ColorType } from '@/components/atoms/Colors/Colors';
import Icon, { IconType } from '@/components/atoms/Icon/Icon';
import { cva } from 'cva';
import { useState } from 'react';

/**
 * The CarouselButtonSize The size of the carousel button.
 *
 * @type {CarouselButtonSize}
 */
export type CarouselButtonSize = 'regular' | 'small';

/**
 * Carousel Button Classes The classes for the carousel components using CVA.
 *
 * @constant
 */
const carouselButtonClasses = cva('rounded-full bg-neutral-200 p-3', {
  variants: {
    state: {
      active:
        'hover:drop-shadow-[0_4px_8px_rgba(0,0,0,0.16)] focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus-400 transition-all duration-200 ease-in-out',
      disabled: 'cursor-not-allowed',
    },
    size: {
      regular: 'p-3',
      small: 'p-1',
    },
  },
});

/**
 * ICarouselButton Interface for the Carousel Button Component
 *
 * @interface
 */
export interface ICarouselButton
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** The icon to display. */
  icon: IconType;
  /** If the button is disabled. */
  disabled?: boolean;
  /** The button size */
  size?: CarouselButtonSize;
}

/**
 * Carousel Button Carousel Button Component to be used in the Carousel
 * Component
 *
 * @param {ICarouselButton} props - The props for the Carousel Button Component.
 * @returns {React.FC<ICarouselButton>} Carousel Button Component
 */
const CarouselButton: React.FC<ICarouselButton> = ({
  icon,
  disabled,
  size = 'regular',
  ...rest
}) => {
  const [hovered, setHovered] = useState(false);
  let iconColorShade: ColorShade = 300;
  let iconColorType: ColorType = 'base';

  if (disabled) {
    iconColorShade = 400;
    iconColorType = 'neutral';
  } else if (hovered) {
    iconColorShade = 400;
    iconColorType = 'base';
  } else {
    iconColorShade = 300;
    iconColorType = 'base';
  }

  return (
    <button
      {...rest}
      disabled={disabled}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      data-testid="carousel-button"
      className={carouselButtonClasses({
        state: disabled ? 'disabled' : 'active',
        size: size,
        class: rest.className,
      })}
    >
      <Icon
        icon={icon}
        size={24}
        colorShade={iconColorShade}
        colorType={iconColorType}
      />
    </button>
  );
};

export default CarouselButton;

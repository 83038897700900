import { getImagePath } from '@/lib/utils/helpers/photoHelpers/getImagePath';
import { ImageLoaderProps } from 'next/image';

/**
 * Loads images from the CDN in squared format
 *
 * @param {ImageLoaderProps} params- Image loader props
 * @returns {string} - Image URL
 */
function cdnSquareImageLoader({
  src,
  width,
  quality,
}: ImageLoaderProps): string {
  /**
   * The CDN base URL
   *
   * @constant {string} cdnBaseUrl - The CDN base URL
   */
  const cdnBaseUrl = process.env.NEXT_PUBLIC_CDN_BASE_URL;

  /** We need the CDN base URL to load images from the CDN */
  if (cdnBaseUrl === undefined) return '';

  /**
   * Image path without cdn base path
   *
   * @constant {string} imageSrcPath - The image src path
   */
  const imagePath = getImagePath({ src, cdnBaseUrl });

  if (quality) {
    return `${cdnBaseUrl}${imagePath}?quality=${quality}&width=${width}&height=${width}&format=webp`;
  } else {
    return `${cdnBaseUrl}${imagePath}?width=${width}&height=${width}&format=webp`;
  }
}

export default cdnSquareImageLoader;

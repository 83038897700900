import CdnSquareImage from '@/components/atoms/CdnSquareImage/CdnSquareImage';

/**
 * ICarouselPreviewNavigation
 *
 * @interface
 */
export interface ICarouselPreviewNavigation {
  /** The carousel current slide */
  currentSlide: number;
  /** The images */
  images: string[];
  /** Set carousel slide */
  setSlide: (index: number) => void;
  /** The optional classes to add to the component */
  classes?: string;
}

/**
 * CarouselPreviewNavigation A preview navigation for the carousel
 *
 * @param {ICarouselPreviewNavigation} props - The props for the
 *   CarouselPreviewNavigation component
 * @returns {React.FC<ICarouselPreviewNavigation>} Component
 */
const CarouselPreviewNavigation: React.FC<ICarouselPreviewNavigation> = ({
  currentSlide,
  images,
  setSlide,
  classes,
}) => {
  return (
    <div
      data-testid="carousel-preview-navigation"
      className={`flex space-x-4 mt-6 justify-center h-[54px] ${classes}`}
    >
      {images.map((src, index) => {
        /**
         * The active state of the current dot
         *
         * @constant {boolean} isActiveSlide
         */
        const isActiveSlide = index === currentSlide;
        return (
          <div
            key={index}
            className={`rounded-lg aspect-square overflow-hidden ${
              isActiveSlide ? 'border-2 border-base-300' : ''
            }`}
          >
            <button
              onClick={() => setSlide(index)}
              key={index}
              className="aspect-square relative w-[54px]"
              data-testid="carousel-preview-button"
            >
              <CdnSquareImage
                src={src}
                layout="fill"
                objectFit="cover"
                loading="lazy"
                sizes="54px"
                className={`${
                  !isActiveSlide
                    ? 'scale-100 hover:scale-[105%] transition-all duration-100'
                    : ''
                }`}
              />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default CarouselPreviewNavigation;

import cdnSquareImageLoader from '@/lib/loaders/cdnSquareImageLoader';
import Image, { ImageProps } from 'next/image';

/**
 * CdnImage An image from the CDN
 *
 * @param {ImageProps} props - The props for the CdnImage component
 * @returns {React.FC<ImageProps>} Component
 */
const CdnSquareImage: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Image
      data-testid={'cdn-square-image'}
      alt={'cdn square image'}
      {...props}
      loader={cdnSquareImageLoader}
    />
  );
};

export default CdnSquareImage;

import { useMemo } from 'react';

/**
 * ICarouselDotNavigation
 *
 * @interface
 */
export interface ICarouselDotNavigation {
  /** The carousel current slide */
  currentSlide: number;
  /** Set carousel slide */
  setSlide: (index: number) => void;
  /** The total number of slides */
  totalSlides: number;
  /**
   * The optional font size class
   *
   * @default 'text-[24px]'
   */
  fontSizeClass?: string;
  /**
   * The space between dots
   *
   * @default 16
   */
  space?: number;
  /** The optional classes to add to the component */
  classes?: string;
}

/**
 * CarouselDotNavigation A dot navigation for the carousel
 *
 * @param {ICarouselDotNavigation} props - The props for the
 *   CarouselDotNavigation component
 * @returns {React.FC<ICarouselDotNavigation>} Component
 */
const CarouselDotNavigation: React.FC<ICarouselDotNavigation> = ({
  currentSlide,
  setSlide,
  totalSlides,
  fontSizeClass = 'text-[24px]',
  space = 16,
  classes,
}) => {
  /**
   * The horizontal space class
   *
   * @constant {string} horizontalSpaceClass
   */
  // Postcss gives us a warning if we use a template string with a number for this class. Using a string instead.
  const horizontalSpaceClass = useMemo(
    () => 'space-x-[' + space + 'px]',
    [space]
  );
  return (
    <div
      data-testid="carousel-dot-navigation"
      className={`${fontSizeClass} font-petco ${horizontalSpaceClass} text-center ${classes}`}
    >
      {Array.from({ length: totalSlides }, (_, index) => {
        /**
         * The active state of the current dot
         *
         * @constant {boolean} isActiveSlide
         */
        const isActiveSlide = index === currentSlide;

        return (
          <button
            key={index}
            onClick={() => setSlide(index)}
            data-testid="carousel-dot-button"
            className={`${
              isActiveSlide ? 'text-base-300' : 'text-base-100'
            } hover:text-base-200 h-[38px]`}
          >
            •<span className="sr-only">Jump to Item {index + 1}</span>
          </button>
        );
      })}
    </div>
  );
};

export default CarouselDotNavigation;

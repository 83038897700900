import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import Link from 'next/link';

/**
 * ICarouselItemReunion Interface for the Carousel Item Reunion Component
 *
 * @interface
 */
export interface ICarouselItemReunion {
  /** The Name of the Pet */
  petName: string;
  /** The Name of the Owner */
  ownerName: string;
  /** The Description of the Reunion */
  description: string;
  /** The Image of the Reunion */
  image: string;
  /** The Link to the Reunion */
  link: string;
}

/**
 * Carousel Item Reunion - A Reunion Carousel Item
 *
 * @param {ICarouselItemReunion} props - The props for the Carousel Item Reunion
 * @returns {React.FC<ICarouselItemReunion>} Carousel Item Component
 */
const CarouselItemReunion: React.FC<ICarouselItemReunion> = ({
  petName,
  ownerName,
  description,
  image,
  link,
}) => {
  return (
    <Link href={link}>
      <a className="focus-visible:outline-none py-[5px] w-full">
        <div
          data-testid="carousel-item-reunion"
          className="group flex flex-col justify-center items-center focus-visible:outline-focus-400"
        >
          <div className="aspect-square overflow-hidden rounded-[20px] md:rounded-3xl w-full relative">
            <ContentfulImage
              layout="fill"
              objectFit="cover"
              alt={petName + ' + ' + ownerName}
              src={image}
              className="scale-100 group-hover:scale-[102%] transition-all duration-100"
              sizes="(max-width: 739px) 100vw, (max-width: 1198px) 50vw, (max-width: 1439px) 33vw, 450px"
            />
          </div>

          <span className="mt-3.5 line-clamp-1 text-h5 font-petco font-semibold">
            {petName} <span className="text-base-300">+</span> {ownerName}
          </span>
          <Paragraph
            size="body4"
            font="petco"
            className="mt-2 text-center line-clamp-2 font-petco max-w-[432px]"
          >
            {description}
          </Paragraph>
        </div>
      </a>
    </Link>
  );
};

export default CarouselItemReunion;
